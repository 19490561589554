/* Global styles index.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto Slab', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    max-width: lg;
    background-color: #f5f5f5;
    padding: 10px;
  }

.paper {
    padding: 20px;
    margin-top: 20px;
    background-color: #f5f5f5;
}

.typographyHeader h4 {
    color: #3f51b5;
    margin-bottom: 20px;
    font-family: 'Roboto Slab', serif; 
}

.typographyHeader h5 {
  color: #3f51b5;
  margin-bottom: 20px;
  font-family: 'Roboto Slab', serif; 
}

.buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;
}

.addRemoveButton {
  margin-right: 10px;
}

.submitButton {
 margin-top: 0px;
}

.audioSection {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.audioPlayer {
  width: 100%;
  max-width: 640px;
  height: 50px;
  border-radius: 4px;
}

.audioPlayer audio {
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
.audioPlayer {
    width: 100%;
}
}

.AudioSettingsForm {
  padding-top: 20px;
  padding-bottom: 20px;
}

.testField {
  margin-bottom: 20px;
  width: 100%;
  margin-top: 20px;
}
.testField textarea {
  resize: vertical;
  overflow: auto;
}

/* SentenceForm styles */

.sentenceForm {
  padding-top: 10px;
  display: flex;
  width: 100%;
}

.flexContainer {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.textField {
  flex-grow: 1;
  flex-basis: 75%;
  width: 100%;
  margin-right: 10px;
}

.formControls {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.formControl {
  display: flex;
}

/* DragDropContext en Draggable stijlen */
.draggableContainer {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 10px #aaa;
  cursor: grab;
  margin-bottom: 10px; /* Voeg wat ruimte toe tussen de items */
}

/* DragHandleIcon container stijlen */
.dragHandleContainer {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container voor select forms */
.flexItem {
  width: 20%;
}